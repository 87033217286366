
@import url('https://fonts.googleapis.com/css?family=Teko&display=swap');

html{
  
  overflow-y: overlay;
  //width: 100vw;
}

.App {
  text-align: center;
  overflow: auto;
  //height: 100vh;
  //background-image: url("https://i.imgur.com/TbGzSrC.jpg");
  //background-size: cover;
}

/*
::-webkit-scrollbar {
  background: #1d385c;
  width: 15px;
}
 
:-webkit-scrollbar-thumb {
  background: rgb(240, 236, 236);
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 20px;
  background: #2e7fea;
}
*/

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

.wrapper{
  
  overflow: auto;
  overflow: hidden;
  position:relative;
  min-height: 100vh;
}


.background{
  
  height: 100%;
  width: 100vw;
  position: fixed;
  //background-image: url(https://i.imgur.com/TbGzSrC.jpg);
  background-image: url(https://i.imgur.com/KUKbLpS.jpg);
  background-size: cover;
  z-index: -1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.info{
  background-position: center center;
  background-size: cover;
  padding: 60px 0 200px;
  position: relative;
  z-index: 2;
  //background-color: #ffeae7;
  //height: 400px;
}

.info-inner{
  
  //position: absolute;
  //top: 54%;
  //left: 50%;
  //transform: translate(-50%, -50%);
  width: 100%;
  //max-width: 1680px;
  max-width: 1920px;
  margin: 0 auto;

}

.small{
  font-size: .75em;
}

.combined-stat{
  margin: 0 auto;
  //width: 15%;
  width: 6em;
  height: 68px;
  border-left: 2px;
  border-left-style: solid;
  padding-left: 2%;
  border-right: 2px;
  border-right-style: solid;
  padding-right: 2%;
}

.combined-stat-title{
  position: absolute;
  margin: 0 auto;
  right: 0;
  left: 0;
}
.combined-stat-amount{
  
  position: absolute;
  right: 0;
  left: 0;
  //top: 5em;
  margin-top: 34px;
}

.text-combined-stats{    
  font-family: 'Teko', sans-serif;
  line-height: 1.75em;
  letter-spacing: 2px;
  font-size: 2.4em;
  font-weight: 500;
  text-decoration: none;
  color: #a5bedc;
}

.info-middle{
  position: fixed; 
  position: absolute;
  top: 20%;
  top: 11%;
  //top: 34%;
  //margin: 0 auto;
  //width: 100%;
  //width: 30%;
  //position: fixed;
    margin: 0 auto;
    margin-top: 200px;
    margin-top: 80px;
   // margin-bottom: 200px;
   // width: 90%;
    max-width: 1600px;
    z-index: -1; 
    width: 30%;
  
  //height: 600px;
  //width: 400px;
  //width: 41%;
  //padding-left: .9375rem;
  //padding-right: .9375rem;

  

  //testing these, remove them when width/underamount
  //position: absolute;
  //top: 3vw;
  left: 0;
  right: 0;
}

.info-inner-middle-column{
  margin: 0 auto;
  width: 20%;
  position: absolute;
  
  //height: 600px;
  //width: 400px;
  //width: 41%;
  //padding-left: .9375rem;
  //padding-right: .9375rem;
}


.info-inner-left{
  //float: left;
  //width: 41%;
  //padding-left: .9375rem;
  //padding-right: .9375rem;

  //testing flex 
  //removing float
  //removing width
  display: inline-block;
  //padding-left: 7.5vw;
  padding-right: 8vw;
  padding-top: 4vw;
}


.info-inner-right{
  //float: right;
  //width: 41%;
  //padding-left: .9375rem;
  //padding-right: .9375rem;

  //testing flex
  //removing float
  //removing width
  //removing both padding
  display: inline-block;
  padding-left: 8vw;
  //padding-right: 7.5vw;
  padding-top: 4vw;
}

.link-box{
  //height: 6vh;
 // max-height: 6vh;
  width: 30%;
  margin: 0 auto;
  height: 40px;
}

.link-box-left{
  
  box-shadow: inset 0px 0px 5px 0px black;
  border-radius: 50px;
  //padding: 2% 0;
 // float: left;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(#dadee0, #a9acad);
  text-align:left;
  display: flex;
  align-items: center;
  //margin-left: 3%;
}

.link-box-left-inner{
  width: 80%;
}
.link-box-right-inner{
  font-family: 'Teko', sans-serif;
  line-height: 1;
  letter-spacing: .5px;
  font-size: 1.3em;
  font-weight: 500;
  text-decoration: none;
  margin: auto;
}

.link-box-right:hover{
  box-shadow: inset 0px 0px 8px 0px black;
}
.link-box-left:hover{
  box-shadow: inset 0px 0px 8px 0px black;
}
.link-box-right{
  border-radius: 50px;
  
  box-shadow: inset 0px 0px 5px 0px black;
  //padding: 4.2% 1.5%;
  //display: flex;
  //align-items: center;
  //height: 100%;
  //text-align: center;
  //float: right;
  /* margin: auto 0; */
  /* margin-top: 10%; */  
  display: flex;
  align-items: center;
  width: 35%;
  height: 100%;
  background-image: linear-gradient(#6aa5b9, #306171);
}

.link-input{
  font-size: 1.5em;
  background-color: transparent;
  border: none;
  vertical-align: middle;
  width: 100%!important;
  height: 100% !important;
}


@media only screen and (max-width: 1500px) {
  .info-middle {position: relative ;}
  //.info-inner-left{padding-left: 7.5vw; padding-right: 7.5vw;}
  //.info-inner-right{padding-left: 7.5vw; padding-right: 7.5vw;}
  //.info-inner{top: 100% !important;}
  .info-inner-left{padding-left: 0px; padding-right: 0px;}
  .info-inner-right{padding-left: 0px; padding-right: 0px;}
  .info-inner{top: 125% ;}



}

@media only screen and (max-width: 1000px) {
  .info-middle {position: relative ;}
  //.info-inner-left{padding-left: 7.5vw; padding-right: 7.5vw;}
  //.info-inner-right{padding-left: 7.5vw; padding-right: 7.5vw;}
  //.info-inner{top: 100% !important;}
  .info-inner-left{padding-left: 0px; padding-right: 0px;}
  .info-inner-right{padding-left: 0px; padding-right: 0px;}
  //.info-inner{top: 125% ;}
  .info-inner{
    display: block;
    top: auto;
    left: auto; 
    transform: none;
  }

  .info{
    padding: 80px 0 200px;
  }



}


@media only screen and (max-width: 800px) {
  .info-middle {width: 90%;}



}

@media only screen and (max-width: 500px) {
  .link-box {margin-left: 19%;}
  .link-box-left {width: 200%;}



}