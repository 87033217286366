.home-wrapper{
  
 // width: 1080px;
  margin: 10px auto 0;
}
.home-content{
  
  margin-top: 25px;
  display: block;
}
.home-content-stats{
  min-height: 400px;
  width: 1080px;
  background: #010306b8;
  border-radius: 10px;
  min-height: 400px;
  width: 90%;
  height: 100%;
  margin: 0 auto;
  max-width: 1080px;
  min-width: 300px;
  margin-bottom: 130px;
}

.home-content-left{
  
 // float: left;
  //width: 734px;
}
.home-content-right{
  
 // float: left;
 // margin-left: 8px;
  //width: 336px;
}

.home-image{
  //background-image: url(https://i.imgur.com/NPm31fr.png);
  background-image: url(https://i.imgur.com/icCNINW.png);
  border-radius: 5px;
  background-size: cover;
  width: 300px;
  height: 210px;
  margin: 0 auto;
}

.home-content-lower
{

}
.home-content-inner
{
  margin: 0 auto;
}

.list-text{
text-align:left;
  font-family: 'Teko', sans-serif;
  line-height: 1.35em;
  letter-spacing: 1px;
  font-size: 130%;
  text-decoration: none;
  color: #56a8de;
  padding-left: 0px;
}
.home-content-lower-inner>ul{
  list-style-type: disc ;
}
.changelog-title{
  
  font-size: 200%;
  border: 1px solid;
  box-shadow: inset 0px 0px 6px 2px #444444;
  background-color: rgba(146, 146, 146, 0.23);
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  text-transform: uppercase;
  text-align: center;
  font-size: 150%;
  color: #d5dbe0;
  letter-spacing: .8px;
  -webkit-user-select: none;
  user-select: none;
}
.list-single{
  margin-left: 3px; 
  text-align: left;
  float:left;
  width: 99%;

}
.date-notes>li{
  display: none;
  margin-bottom: 5px;
  padding-top: 5px;
}
.date-notes>li{margin-bottom:10px;
}

.date-notes:after {
  clear: both;
  content: "";
  display: table;
}

.date-notes .list-single{
  
  border-top: 1px solid;
}

.date-notes{
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  border-bottom: 1px #7c9cab solid;
  border: 1px solid;
  box-shadow: inset 0px 0px 6px 2px #444444;
  background-color: rgba(146, 146, 146, 0.23);
  width: 40%;
  margin-left: auto;
  margin-bottom: 5px;
  margin-right: auto;
  //transition: all .15s ease; 
  // transition: all .69s cubic-bezier(0.75, 1.91, 0.87, 0.55);
  max-width: 90%;
}


.main-page{

  display: block;
  flex-wrap: wrap;
  color: white;
  font-size:150%;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 15px;
  padding-bottom: 25px;
}

.main-title{

  font-size: 24px;
  font-weight: 500;
  letter-spacing: .025em;
  margin: 0;
  line-height: 2.3em;
  display: flex;
}


.filter{
  
  margin-left: auto;
  padding: 0;
}

@media only screen and (max-width: 420px) {
  
  

  .list-text
  {
   font-size:80%; 
  }
      
    
  }



  .filter .filter-item .btn {
    color: #e5ebef;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-width: 150px;
    height: 100%;
    padding: 0 15px;
    margin: 0;
    font-weight: 400;
    text-transform: none;
    letter-spacing: 0;
    background-color: #272043;
    border: none;
    -webkit-transition: background-color .3s;
    transition: background-color .3s;
    padding: 7.5px 20px;
}

.btn{
  
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: .05em;
  text-align: center;
  padding: 10px 20px;
    background-color: #413c75;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn{
  
  font-family: 'Teko', sans-serif;
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-size: 1.2rem;
  line-height: 1.5;
  -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.filter .filter-item {
  height: 36px;
  margin: 5px;
  border-radius: 5px;
}

.dropdown-menu{
  display: none;
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(0px, 36px, 0px);
  z-index: 10;
  max-height: 30vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.dropdown-menu.show{
  display: block;
}

.filter-menu-item{
  
  color: #7986a3;
  font-size: 15px;
  padding: 7.5px 20px;
  cursor: pointer;
}

.main-card{

  background: #1d425de6;
  background: rgb(29,66,93);
  background: linear-gradient(90deg, rgba(29,66,93,1) 0%, rgba(60,103,135,0.8267682072829132) 50%, rgba(29,66,93,1) 100%);
  padding: 20px;
  border-radius: 5px;
  border: none;


  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;

  
  box-sizing: border-box;
}

.update{

  max-width: 800px;
  //margin: 0 auto;
  padding: 50px;
  padding-top: 0px;
  margin: 0 auto;

  
  box-sizing: border-box;
}

.update-header{

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  
  box-sizing: border-box;
}

.update-contents{
text-align:left;
}
.update-contents>h3{
  margin-top: 0px;
  color: #7986a3;
  margin-bottom: 10px;
  letter-spacing: 1px;
}
.update-header>h3{
  
  color: #7986a3;
}
.update-contents>ul{
  margin-top: 0px;
  margin-bottom: 0px;
  list-style: disc ;
  font-size: 90%;
}

.dropdown-toggle:after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}

.list-contents>li{
  
  margin-bottom: 15px;
}



@media only screen and (max-width: 650px) {
  
  

  .update
  {
   padding: 0px; 
  }
  .main-title{
    
    font-size: 20px;
    line-height: 1.3em;
  }

  .update-header>h1{
    
    font-size: 120%;
  }
  .update-header>h3{
    
    font-size: 100%;
  }
  .update-contents>h3{
    
    font-size: 100%;
  }
  .list-contents>li{
    
    font-size: 80%;
  }
  .update-contents>ul{
    
  padding-left: .75em;
  }
      
    
  }

  @media only screen and (max-width: 350px) {
    
    
  
    .update
    {
     padding: 0px; 
    }
    .main-title{
      
      font-size: 16px;
      line-height: 1.3em;
    }
  
    .update-header>h1{
      
      font-size: 110%;
    }
    .update-header>h3{
      
      font-size: 90%;
    }
    .update-contents>h3{
      
      font-size: 90%;
    }
    .list-contents>li{
      
      font-size: 68%;
    }
    .update-contents>ul{
      
    padding-left: .5em;
    }
        
      
    }